import { FC } from 'react';

interface IDetailElement {
  title: string;
  content?: string;
  onPress?: any;
  containerStyle?: string;
}

export const DetailElement: FC<IDetailElement> = ({
  title,
  content,
  onPress,
  containerStyle,
}) => {
  return (
    <div
      onClick={() => onPress && onPress()}
      className={containerStyle ?? 'w-full sm:w-4/12 mt-8 pr-5'}
    >
      <p className="medium text-neutral-500 text-sm">{title}</p>
      <p className="mt-2 text-sm text-neutral-900 cursor-pointer">{content}</p>
    </div>
  );
};
