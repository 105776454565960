import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { generalApi } from '../../api';
import { Coordinates, GiftCard, ILocation } from '../../types';

interface IGeneralState {
  loading: boolean;
  addresses: Coordinates[];
  locations: ILocation[];
  giftCards: GiftCard[];
}

const initialState: IGeneralState = {
  loading: false,
  addresses: [],
  locations: [],
  giftCards: [],
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAddresses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAddresses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.addresses = payload;
    });
    builder.addCase(getAllAddresses.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.locations = payload;
    });
    builder.addCase(getLocations.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getGiftCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGiftCards.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.giftCards = payload;
    });
    builder.addCase(getGiftCards.rejected, (state) => {
      state.loading = false;
    });
  },
});

const getAllAddresses = createAsyncThunk('getAllAddresses', async () => {
  try {
    const { data } = await generalApi.getAllAddresses();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getLocations = createAsyncThunk('getLocations', async () => {
  try {
    const { data } = await generalApi.getLocations();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getGiftCards = createAsyncThunk('getGiftCards', async () => {
  try {
    const { data } = await generalApi.getGiftCards();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

export const generalSelector = (state: RootState) => state.general;
export { getAllAddresses, getLocations, getGiftCards };

export default generalSlice.reducer;
