import { Cell } from 'react-table';
import { AdminRole, IOrder, OrdersStatuses } from '../types';
import numeral from 'numeral';
import { VehicleIcon } from '../helpers/riders.helpers';

const paymentMethods = [
  { value: 'TRANSFER', option: 'Transfer' },
  { value: 'WALLET', option: 'Wallet' },
  { value: 'CARD', option: 'Card' },
] as { value: string; option: string }[];

const orderStatus = [
  { value: 'PLACED', label: 'Placed' },
  { value: 'CONFIRMED', label: 'Confirmed' },
  { value: 'PREPARING', label: 'Preparing' },
  { value: 'READY_FOR_PICKUP', label: 'Ready for Pickup' },
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'ENROUTE', label: 'Enroute' },
  { value: 'ARRIVED', label: 'Arrived' },
  { value: 'DELIVERED', label: 'Delivered', hideFor: ['LOGISTICS'] },
  { value: 'CANCELED', label: 'Canceled' },
] as { value: string; label: string; hideFor?: AdminRole[] }[];

const statusColors = {
  PREPARING: 'bg-preparing',
  ENROUTE: 'bg-enroute',
  DELIVERED: 'bg-delivered',
  CANCELED: 'bg-canceled',
  SCHEDULED: 'bg-chow-blue',
  PLACED: 'bg-placed',
  ACCEPTED: 'bg-accepted',
  successful: 'bg-enroute',
  ACTIVE: 'bg-enroute',
  INACTIVE: 'bg-canceled',
  Paid: 'bg-enroute',
  Pending: 'bg-preparing',
  OUT_OF_STOCK: 'bg-preparing',
  Enabled: 'bg-enroute',
  Disabled: 'bg-canceled',
};

const orderGroups = [
  { name: 'All Orders', value: '' },
  { name: 'New Orders', value: 'CONFIRMED' },
  // { name: 'Confirmed', value: 'CONFIRMED' },
  // { name: 'Preparing', value: 'PREPARING' },
  { name: 'Accepted', value: 'ACCEPTED' },
  { name: 'Ready for Pickup', value: 'READY_FOR_PICKUP' },
  { name: 'Enroute', value: 'ENROUTE' },
  { name: 'Arrived', value: 'ARRIVED' },
  // { name: 'Delivered Orders', value: 'DELIVERED' },
  { name: 'Cancelled Orders', value: 'CANCELED' },
  { name: 'Scheduled Orders', value: 'SCHEDULED' },
  { name: 'Follow Up', value: 'FLAGGED' },
  { name: 'Other Channels', value: 'OTHER_CHANNELS' },
];

const paymentStatus = [
  { name: 'Pending', value: 0 },
  { name: 'Confirmed', value: 1 },
];

const orderColumns = (type: 'abandoned' | 'paid' = 'paid') => [
  {
    Header: 'Order Slug',
    accessor: 'slug',
  },

  type === 'paid'
    ? {
        Header: 'Date & Time',
        accessor: 'timePaid',
      }
    : {
        Header: 'Date & Time',
        accessor: 'timePlaced',
      },

  {
    Header: 'Customer',
    accessor: 'customer',
    Cell: ({ row }: Cell) =>
      row.values.customer
        ? row.values.customer.fullName
        : row.values.user
        ? `${row.values.user.firstName} ${row.values.user.lastName}`
        : '-',
  },
  {
    Header: 'Distance from Kitchen (KM)',
    accessor: 'estimatedDistanceTravelled',
    Cell: ({ row }: Cell) =>
      `${(row.values.estimatedDistanceTravelled / 1000).toFixed(2)}`,
  },
  {
    Header: 'Pilot',
    accessor: 'pilot',
    Cell: ({ row }: Cell) => (
      <div className="flex items-center space-x-2">
        {row.values.pilot && row.values.pilot?.vehicles?.length > 0 && (
          <div className="h-5 w-5 rounded-full bg-primary-50 flex items-center justify-center">
            <img
              src={VehicleIcon(row.values.pilot?.vehicles[0].type)}
              alt="vehicle"
            />
          </div>
        )}
        <p>{row.values.pilot ? row.values.pilot.user.firstName : '-'}</p>
      </div>
    ),
  },
  {
    Header: 'Delivery Address',
    accessor: 'deliveryAddress',
    Cell: ({ row }: Cell) => row.values.deliveryAddress.address,
  },
  {
    Header: 'Kitchen',
    accessor: 'kitchens',
    Cell: ({ row }: Cell) => row.values.kitchens[0].name,
  },
  {
    Header: 'Order Amount',
    accessor: 'totalPrice',
    Cell: ({ row }: Cell) => `₦${numeral(row.values.totalPrice).format('0,0')}`,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Quick Actions',
    accessor: 'user',
  },
];

const orderLabels = [
  { value: 'OFFICE', option: 'Office' },
  { value: 'HOME', option: 'Home' },
  { value: 'OTHER', option: 'Other' },
  { value: 'WORK', option: 'Work' },
] as { value: string; option: string }[];

const orderViews = [{ name: 'List' }, { name: 'Map' }];

const orderQuantity = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
];

export const returnDeliveryInformation = (order: IOrder) => [
  {
    title: 'Kitchen Assigned',
    value: order?.kitchens.map((kitchen) => kitchen.name).join(', '),
    changeButton: true,
  },
  {
    title: 'Delivery Rider Assigned',
    value:
      order?.pilot &&
      order.pilot.user &&
      `${order.pilot.user.firstName} ${order.pilot.user.lastName}`,
    changeButton: true,
  },
  {
    title: "Delivery Rider's phone number",
    value:
      order?.pilot && order.pilot.user && `${order.pilot.user.phoneNumber}`,
    changeButton: false,
  },
  {
    title: 'Order Channel',
    value: order?.channel && order.channel.channelName,
    changeButton: false,
  },
];

export const initialOrdersStatuses: OrdersStatuses = {
  PLACED: 0,
  PREPARING: 0,
  READY_FOR_PICKUP: 0,
  CONFIRMED: 0,
  ACCEPTED: 0,
  ENROUTE: 0,
  DELIVERED: 0,
  CANCELED: 0,
  SCHEDULED: 0,
};

export {
  paymentMethods,
  orderStatus,
  statusColors,
  orderGroups,
  paymentStatus,
  orderColumns,
  orderLabels,
  orderViews,
  orderQuantity,
};
