import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import { brandsSelector, getAllBrands } from '../store/slices';
import React from 'react';
import {
  FoodGroup,
  FoodItem,
  FoodItemParams,
  Option,
  ResolvedResponse,
} from '../types';
import { FoodItemScreenFilters } from '../views/features/dashboard/food-items/types';
import { useFetch } from './useFetch';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';

export const useListFoodItems = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { brands } = useAppSelector(brandsSelector);

  const queryClient = useQueryClient();

  const [modal, setModal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [activeBrand, setActiveBrand] = React.useState<number>();
  const [editGroup, setEditGroup] = React.useState<string>();
  const [editOption, setEditOption] = React.useState<Option>();
  const [listFilters, setListFilters] = React.useState<FoodItemParams>({
    foodItemName: '',
    page: 1,
  });
  const [activeFilter, setActiveFilter] =
    React.useState<FoodItemScreenFilters>('FOOD_ITEMS');

  const { useFetchFoodItem, useFetchFoodGroup, useFetchOptions } = useFetch();

  const { data, isLoading } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { count: 45, brand: activeBrand, ...listFilters },
    { enabled: activeFilter === 'FOOD_ITEMS' }
  );

  const { data: foodGroups, isLoading: groupsLoading } = useFetchFoodGroup<
    ResolvedResponse<FoodGroup[]>
  >(
    undefined,
    { ...listFilters, count: 45 },
    { enabled: activeFilter === 'FOOD_GROUPS' }
  );

  const { data: options, isLoading: optionsLoading } = useFetchOptions(
    { ...listFilters, count: 45 },
    { enabled: activeFilter === 'OPTIONS' }
  );

  const filterList = (filter: FoodItemScreenFilters) => {
    setActiveFilter(filter);
    setSearchParams({
      list: filter,
      page: '1',
    });
  };

  const editItem = (
    id?: string,
    e?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();

    if (activeFilter === 'FOOD_GROUPS') {
      setEditGroup(id);
      return setModal(1);
    }

    if (activeFilter === 'OPTIONS') {
      const g = options?.data?.find((group) => group.id === id);

      if (g?.id) {
        setEditOption(g);
        return setModal(3);
      }
    }

    if (id) {
      return navigate(`/dashboard/food-items/edit/${id}`);
    }
  };

  const currentPage = React.useMemo(
    () => parseInt(searchParams.get('page') ?? '1'),
    [searchParams]
  );

  const filterFoodItems = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;

    setActiveBrand(parseInt(value));
  };

  const handlePageClick = (data: any) => {
    const page = data.selected + 1;
    setListFilters({ ...listFilters, page });

    searchParams.set('page', page);
    navigate(`/dashboard/food-items?${searchParams.toString()}`);
  };

  const duplicateItem = (id: string) => {
    if (id && activeFilter === 'FOOD_ITEMS') {
      return navigate(`/dashboard/food-items/create/?duplicate=${id}`);
    }

    if (id && activeFilter === 'FOOD_GROUPS') {
      setEditGroup(id);
      return setModal(2);
    }

    if (id && activeFilter === 'OPTIONS') {
      const g = options?.data?.find((group) => group.id === id);

      if (g?.id) {
        setEditOption(g);
        return setModal(4);
      }
    }
  };

  const createItem = () => {
    if (activeFilter === 'FOOD_ITEMS') {
      return navigate(`/dashboard/food-items/create/`);
    }
    if (activeFilter === 'FOOD_GROUPS') {
      return setModal(1);
    }
    if (activeFilter === 'OPTIONS') {
      return setModal(3);
    }
  };

  const delistItem = async (key: string) => {
    setLoading(true);
    try {
      await mealsApi.updateFoodItem(key, { status: 'DELETED' });
      toast.success('Food item removed!');
      queryClient.refetchQueries(['food-items']);
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setEditGroup(undefined);
    setEditOption(undefined);
    setModal(0);
  };

  const filterTable = (filters: FoodItemParams) => {
    Object.entries(filters).map(([key, value]) => searchParams.set(key, value));
    searchParams.set('page', '1');
    navigate(`/dashboard/food-items?${searchParams.toString()}`);
  };

  React.useEffect(() => {
    brands && brands.length < 1 && dispatch(getAllBrands());
    // eslint-disable-next-line
  }, [dispatch]);

  React.useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('create')) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
  }, [pathname]);

  React.useEffect(() => {
    const list = searchParams.get('list');
    const page = searchParams.get('page');
    const foodItemName = searchParams.get('foodItemName');
    const foodGroupName = searchParams.get('foodGroupName');
    const foodGroupOptionName = searchParams.get('foodGroupOptionName');

    list
      ? setActiveFilter(list as FoodItemScreenFilters)
      : setActiveFilter('FOOD_ITEMS');

    if (foodItemName || page || foodGroupName || foodGroupOptionName) {
      setListFilters({
        foodItemName: foodItemName ?? '',
        page: parseInt(page ?? '1'),
        foodGroupOptionName: foodGroupOptionName ?? '',
        foodGroupName: foodGroupName ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    data,
    modal,
    brands,
    options,
    loading,
    isLoading,
    editGroup,
    foodGroups,
    editOption,
    openDrawer,
    activeBrand,
    currentPage,
    searchParams,
    activeFilter,
    groupsLoading,
    optionsLoading,
    navigate,
    editItem,
    closeModal,
    delistItem,
    createItem,
    filterList,
    filterTable,
    duplicateItem,
    setOpenDrawer,
    filterFoodItems,
    handlePageClick,
  };
};
