import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Loading } from '../views/components';
import { DashboardLayout } from '../views/components/layouts';
import CreateNewOrder from '../views/features/dashboard/orders/create';
import FoodItemsScreen from '../views/features/dashboard/food-items';
import MarketingCategories from '../views/features/dashboard/categories/marketing-categories';
import Cashback from '../views/features/dashboard/coupons/cashback';

const DashboardHome = lazy(() => import('../views/features/dashboard/home'));
const Brand = lazy(() => import('../views/features/dashboard/brands/brand'));

const Brands = lazy(() => import('../views/features/dashboard/brands/brands'));

const Categories = lazy(
  () => import('../views/features/dashboard/categories/categories')
);

const Category = lazy(
  () => import('../views/features/dashboard/categories/category')
);

const CreateKitchen = lazy(
  () => import('../views/features/dashboard/kitchens/createKitchen')
);

const Kitchen = lazy(
  () => import('../views/features/dashboard/kitchens/kitchen')
);

const Kitchens = lazy(
  () => import('../views/features/dashboard/kitchens/kitchens')
);

const Variants = lazy(
  () => import('../views/features/dashboard/variants/variants')
);

const Meal = lazy(() => import('../views/features/dashboard/meals/meal'));

const Meals = lazy(() => import('../views/features/dashboard/meals/meals'));

const CreateOrder = lazy(
  () => import('../views/features/dashboard/orders/createOrder')
);

const Order = lazy(() => import('../views/features/dashboard/orders/order'));

const Orders = lazy(() => import('../views/features/dashboard/orders/orders'));

const CreateMeal = lazy(
  () => import('../views/features/dashboard/meals/createMeal')
);

const Users = lazy(() => import('../views/features/dashboard/users/users'));

const Pilots = lazy(() => import('../views/features/dashboard/pilots/pilots'));

const Pilot = lazy(() => import('../views/features/dashboard/pilots/pilot'));

const CreatePilot = lazy(
  () => import('../views/features/dashboard/pilots/createPilot')
);

const CreateBrand = lazy(
  () => import('../views/features/dashboard/brands/createBrand')
);

const CreateUser = lazy(
  () => import('../views/features/dashboard/users/createUser')
);

const User = lazy(() => import('../views/features/dashboard/users/user'));

const Customers = lazy(
  () => import('../views/features/dashboard/customers/customers')
);

const Promotions = lazy(
  () => import('../views/features/dashboard/promotions/promotions')
);

const Promotion = lazy(
  () => import('../views/features/dashboard/promotions/promotion')
);

const CreatePromotion = lazy(
  () => import('../views/features/dashboard/promotions/createPromotion')
);

const Transactions = lazy(
  () => import('../views/features/dashboard/transactions')
);

const WalletTransactions = lazy(
  () => import('../views/features/dashboard/wallet-transactions')
);

const Addresses = lazy(() => import('../views/features/dashboard/addresses'));

const Coupons = lazy(() => import('../views/features/dashboard/coupons'));

const Coupon = lazy(() => import('../views/features/dashboard/coupons/coupon'));

const CreateCoupon = lazy(
  () => import('../views/features/dashboard/coupons/create')
);

const AbandonedCheckout = lazy(
  () => import('../views/features/dashboard/abandoned-checkout')
);

const Branches = lazy(() => import('../views/features/dashboard/branches'));
const CreateBranch = lazy(
  () => import('../views/features/dashboard/branches/create')
);
const UpdateBranch = lazy(
  () => import('../views/features/dashboard/branches/update')
);

const BrandReviews = lazy(
  () => import('../views/features/dashboard/brands/brandReviews')
);

const GiftCards = lazy(() => import('../views/features/dashboard/gift-cards'));

export const DashboardRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <DashboardLayout>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: 'brands',
        element: <Brands />,
      },
      {
        path: '/brand/:brandSlug',
        element: <Brand />,
      },
      {
        path: '/brands/:action',
        element: <CreateBrand />,
        children: [{ path: ':slug', element: <CreateBrand /> }],
      },
      {
        path: '/brand/:brandSlug/brand-ratings',
        element: <BrandReviews />,
      },

      {
        path: 'variants',
        element: <Variants />,
      },
      {
        path: '/variants/:variantSlug',
        element: <Brand />,
      },

      {
        path: 'kitchens',
        element: <Kitchens />,
      },
      {
        path: '/kitchen/:kitchenId',
        element: <Kitchen />,
      },
      {
        path: 'kitchens/:action',
        element: <CreateKitchen />,
        children: [{ path: ':id', element: <CreateKitchen /> }],
      },

      {
        path: 'meals',
        element: <Meals />,
      },
      {
        path: '/meal/:mealSlug',
        element: <Meal />,
      },
      {
        path: 'meals/:action',
        element: <CreateMeal />,
        children: [{ path: ':slug', element: <CreateMeal /> }],
      },

      {
        path: 'orders',
        element: <Orders />,
      },
      {
        path: '/order/:orderId',
        element: <Order />,
      },
      {
        path: 'orders/:action',
        element: <CreateOrder />,
        children: [{ path: ':id', element: <CreateOrder /> }],
      },
      {
        path: 'orders/create',
        element: <CreateNewOrder />,
      },
      {
        path: 'categories',
        element: <Categories />,
      },
      {
        path: 'marketing-categories',
        element: <MarketingCategories />,
      },
      {
        path: 'categories/:categorySlug',
        element: <Category />,
      },
      {
        path: 'home',
        element: <DashboardHome />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: '/user/:userId',
        element: <User />,
      },
      {
        path: 'users/:action',
        element: <CreateUser />,
        children: [{ path: ':id', element: <CreateUser /> }],
      },

      {
        path: 'customers',
        element: <Customers />,
      },
      {
        path: '/customer/:userId',
        element: <User />,
      },
      {
        path: 'customers/:action',
        element: <CreateUser />,
        children: [{ path: ':id', element: <CreateUser /> }],
      },

      {
        path: 'pilots',
        element: <Pilots />,
      },
      {
        path: 'pilots/:action',
        element: <CreatePilot />,
        children: [{ path: ':id', element: <CreatePilot /> }],
      },
      {
        path: 'pilot/:id',
        element: <Pilot />,
      },

      {
        path: '/',
        element: <DashboardHome />,
      },

      {
        path: 'promotions',
        element: <Promotions />,
      },
      {
        path: 'promotion/:id',
        element: <Promotion />,
      },
      {
        path: 'promotions/:action',
        element: <CreatePromotion />,
        children: [{ path: ':id', element: <CreatePromotion /> }],
      },

      {
        path: 'transactions',
        element: <Transactions />,
      },

      {
        path: 'wallet-transactions',
        element: <WalletTransactions />,
      },

      {
        path: 'addresses',
        element: <Addresses />,
      },

      {
        path: 'coupons',
        element: <Coupons />,
      },
      {
        path: 'coupon/:slug',
        element: <Coupon />,
      },
      {
        path: 'coupons/cashback',
        element: <Cashback />,
      },
      {
        path: 'coupons/:action',
        element: <CreateCoupon />,
        children: [{ path: ':slug', element: <CreateCoupon /> }],
      },

      {
        path: 'abandoned-checkout',
        element: <AbandonedCheckout />,
      },

      {
        path: 'branches',
        element: <Branches />,
      },
      {
        path: 'branch/create',
        element: <CreateBranch />,
      },
      {
        path: 'branch/edit/:id',
        element: <UpdateBranch />,
      },

      {
        path: 'gift-cards',
        element: <GiftCards />,
      },

      {
        path: 'food-items',
        element: <FoodItemsScreen />,
      },
      {
        path: 'food-items/:action',
        element: <FoodItemsScreen />,
        children: [{ path: ':id', element: <FoodItemsScreen /> }],
      },
    ],
  },
];
