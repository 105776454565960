import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from './redux.hooks';

interface IUseEdit<T> {
  name: string;
  data: T | null;
  setPayload: (x: T) => any;
  callback?: (x?: any) => any;
  getState: () => void;
  excludes?: (keyof T)[];
}

type IParams = {
  action: 'create' | 'edit';
  slug?: string;
  id?: string;
};

export const useEdit = <T>({
  name,
  data,
  excludes,
  getState,
  callback,
  setPayload,
}: IUseEdit<T>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { action, slug } = useParams<IParams>();
  const [edit, setEdit] = React.useState<boolean>(false);

  const navigateToPage = React.useCallback(() => {
    navigate(`/dashboard/${name}`);
  }, [navigate, name]);

  React.useEffect(() => {
    if (edit && data && data !== null) {
      let br: any = { ...data };

      if (excludes?.length) {
        excludes.forEach((key) => {
          if (br[key]) {
            br[key] = null;
          }
        });
      }

      setPayload(br);

      callback && callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, edit, setPayload, callback]);

  React.useEffect(() => {
    action && action !== 'create' && action !== 'edit' && navigateToPage();

    if (action === 'edit' && slug) {
      setEdit(true);
      getState();
    } else if (action === 'create' && slug) {
      navigateToPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, navigateToPage, slug, dispatch]);

  return { edit };
};
