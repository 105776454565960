import React from 'react';
import { chevronDown } from '../../../assets/svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RangeStatus } from '../../../constants';
import { DateRanges } from '../../../types';

export const DateRangePicker = ({
  handleFilter,
  showPredefined,
}: {
  handleFilter: (
    range?: DateRanges,
    dates?: {
      startDate: Date | null;
      endDate: Date | null;
    }
  ) => void;
  showPredefined?: boolean;
}) => {
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [range, setRange] = React.useState(
    showPredefined ? 'CUSTOM' : RangeStatus[0].value
  );
  const [date, setDate] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: new Date(),
    endDate: null,
  });

  const _toggleFilter = () => {
    setFilterOpen((prev) => !prev);
  };

  const onCalenderChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDate({ ...date, startDate: start, endDate: end });

    if (start && end) {
      setRange('CUSTOM');
      handleFilter(undefined, { startDate: start, endDate: end });

      _toggleFilter();
    }
  };

  const onFilterChange = (value: DateRanges) => {
    setRange(value);
    handleFilter(value);

    setDate({
      startDate: new Date(),
      endDate: null,
    });

    _toggleFilter();
  };

  return (
    <>
      {filterOpen && (
        <div
          className="absolute z-30 inset-0 h-full w-full bg-black opacity-10 rounded-lg"
          onClick={_toggleFilter}
        />
      )}

      <div className="relative z-30 overflow-visible w-full">
        {date.startDate && date.endDate && (
          <div className="flex space-x-4 items-center text-xs mb-1 absolute -bottom-6 z-20 w-full">
            <p className="text-center text-neutral-700 w-full">
              {date.startDate?.toDateString()} - {date.endDate?.toDateString()}
            </p>
          </div>
        )}

        <button
          className="px-4 py-2 rounded-lg bg-neutral-200 flex justify-between items-center space-x-8 relative z-20 w-full"
          onClick={_toggleFilter}
        >
          <p>
            {range === 'CUSTOM'
              ? 'Pick custom date'
              : RangeStatus.filter(({ value }) => value === range)[0].option}
          </p>
          <img src={chevronDown} alt="chevron" />
        </button>

        {filterOpen && (
          <div className="mt-5 sm:w-96 sm:mt-0 sm:absolute top-10 left-5 flex flex-col items-center sm:flex-row p-2 rounded shadow border border-grey-2 bg-white space-x-2 z-40">
            <div>
              <DatePicker
                selected={date.startDate}
                onChange={onCalenderChange}
                startDate={date.startDate}
                endDate={date.endDate}
                filterDate={(date) => {
                  return new Date() > date;
                }}
                selectsRange
                inline
              />
              {date.startDate && date.endDate && (
                <div className="text-xs mb-1">
                  <p className="text-neutral-700">
                    {date.startDate?.toDateString()} -{' '}
                    {date.endDate?.toDateString()}
                  </p>
                  <button
                    className="underline text-primary-700 mt-1"
                    onClick={() =>
                      setDate({ startDate: new Date(), endDate: null })
                    }
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>

            {showPredefined && (
              <div className="sm:w-4/12 sm:space-y-1 flex justify-center sm:flex-col flex-wrap">
                {RangeStatus.map((predefinedRange) => (
                  <button
                    className={`px-3 py-1 rounded text-sm text-left mr-3 mt-2 sm:mt-0 sm:mr-0 semibold ${
                      range === predefinedRange.value
                        ? 'bg-slate-400 text-white'
                        : 'bg-slate-200'
                    }`}
                    onClick={() => onFilterChange(predefinedRange.value)}
                    key={predefinedRange.value}
                  >
                    {predefinedRange.option}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
